import React from "react";
import { Link } from "@tanstack/react-router";

function TitleCell({ row, getValue }) {
  return (
    <Link to="/deliverables/$id" params={{id: row.original.id}}>
      <div className="h-full w-full">{getValue()}</div>
    </Link>
  );
}

export default TitleCell;
