import React from "react";
import usePagination from "@mui/material/usePagination";

const ChangePageButton = ({ path, text, onClick, disabled }) => {
  return (
    <button
      className="relative size-10 select-none rounded-full hover:bg-primary-500/5 hover:transition-all active:bg-primary-500/10 disabled:pointer-events-none disabled:text-gray-400"
      type="button"
      onClick={onClick}
      disabled={disabled}>
      <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          aria-hidden="true"
          className="size-4">
          <path strokeLinecap="round" strokeLinejoin="round" d={path}></path>
        </svg>
      </span>
      <span className="sr-only">{text}</span>
    </button>
  );
};

const ActivePage = ({ page }) => {
  return (
    <button
      aria-current="page"
      className="relative size-10 select-none rounded-full bg-primary-500 text-center align-middle font-sans text-sm font-medium uppercase text-white shadow-md shadow-primary-500/10 hover:shadow-lg hover:shadow-primary-500/20 hover:transition-all focus:opacity-[0.85] focus:shadow-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600  active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
      type="button">
      {page}
    </button>
  );
};

const DefaultPage = ({ page, onClick }) => {
  return (
    <button
      className="relative size-10 select-none rounded-full text-center align-middle text-sm font-medium uppercase hover:bg-primary-500/5 hover:transition-all active:bg-primary-500/10 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
      type="button"
      onClick={onClick}>
      {page}
    </button>
  );
};

const Pagination = (props) => {
  const { onPageChange, pageCount, siblingCount = 1, currentPage, pageSize } = props;

  const { items } = usePagination({
    count: pageCount,
    page: currentPage,
    siblingCount: siblingCount,
    pageSize: pageSize,
    onChange: (event, page) => onPageChange(page),
  });

  return (
    <nav className="flex items-center gap-2" aria-label="Pagination">
      {items.map(({ page, type, selected, ...item }, index) => {
        let children = null;

        if (type === "start-ellipsis" || type === "end-ellipsis") {
          children = (
            <span key={index} className="relative size-10 text-center content-center text-sm font-semibold text-gray-700 focus:outline-offset-0">
              ...
            </span>
          );
        } else if (type === "page") {
          children = selected ? (
            <ActivePage key={index} page={page} />
          ) : (
            <DefaultPage key={index} page={page} {...item} />
          );
        } else if (type === "previous") {
          children = <ChangePageButton key={index} path="m15 19-7-7 7-7" text="Previous" {...item} />;
        } else if (type === "next") {
          children = <ChangePageButton key={index} path="m9 5 7 7-7 7" text="Next" {...item} />;
        }

        return children;
      })}
    </nav>
  );
};

export default Pagination;
