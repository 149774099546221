import React from "react";
import { Link } from "@tanstack/react-router";
import { useLocation } from "@tanstack/react-router";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";

const SidebarNav = ({ className, items, ...props }) => {
  const pathname = useLocation({
    select: (location) => location.pathname,
  });

  return (
    // <>
    //   <nav className="flex flex-col gap-2">
    //     <div className="flex flex-col">
    //       <div className="flex items-center gap-1 py-2 text-gray-600">
    //         <UsersRound size={16} strokeWidth={1.6} />
    //         <span>Teams</span>
    //       </div>
    //       <div className="flex flex-col">
    //         {[1, 2, 3].map((teamId) => (
    //           <Link
    //             key={teamId}
    //             to={`/settings/teams/${teamId}`}
    //             className="px-6 py-1 rounded-md hover:bg-gray-200 focus:bg-gray-300 [&.active]:bg-blue-600 [&.active]:text-white">
    //             Teams {teamId}
    //           </Link>
    //         ))}
    //         <Link to="/settings/teams/new" className="flex items-center gap-1 p-1 rounded-md hover:bg-gray-200 focus:bg-gray-300 [&.active]:bg-blue-600 [&.active]:text-white">
    //           <Plus size={16} strokeWidth={1.6} />
    //           <span>New Team</span>
    //         </Link>
    //       </div>
    //     </div>
    //   </nav>
    // </>
    // TODO: Implement the above code. Add groups to the sidebar nav items. Add icons to the sidebar nav items.
    <nav className={cn("flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1", className)} {...props}>
      {items.map((item) => (
        <Link
          key={item.href}
          to={item.href}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            pathname === item.href
              ? "bg-blue-600 text-white hover:bg-blue-600 hover:text-white"
              : "hover:bg-gray-200 focus:bg-gray-300", // TODO: Move to tailwind config or style.css
            "justify-start flex gap-1",
          )}>
          {item.title}
        </Link>
      ))}
    </nav>
  );
};

export default SidebarNav;
