import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/ui/form";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";
import { Button } from "@/ui/button";
import {
  MultiSelector,
  MultiSelectorContent,
  MultiSelectorInput,
  MultiSelectorItem,
  MultiSelectorList,
  MultiSelectorTrigger,
} from "@/ui/multi-select";
import _ from "lodash";

const teamFormSchema = z.object({
  name: z
    .string()
    .min(2, {
      message: "Name must be at least 2 characters.",
    })
    .max(30, {
      message: "Name must not be longer than 30 characters.",
    }),
  kind: z.string({
    required_error: "Please select type of the team",
  }),
  member_ids: z.array(z.string()),
});

interface FormTeamProps {
  defaultValues: {
    name?: string;
    kind?: string;
    member_ids?: string[];
  };
  memberOptions: {
    value: string;
    label: string;
  }[];
  onSubmit: (data: { name: string; kind: string; member_ids: string[] }) => void;
  SubmitText: string;
  ResetText?: string;
}

const FormTeam: React.FC<FormTeamProps> = ({
  defaultValues: values,
  memberOptions,
  onSubmit,
  SubmitText,
  ResetText,
}) => {
  const defaultValues = _.merge(values, { member_ids: [] });
  const form = useForm({
    resolver: zodResolver(teamFormSchema),
    defaultValues: defaultValues,
    mode: "onChange",
  });
  React.useEffect(() => {
    form.reset(defaultValues); // Hack to reset form when defaultValues change
  }, [defaultValues]);

  const inputRef = React.useRef();
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input placeholder="Name" {...field} />
              </FormControl>
              <FormDescription>
                This is a name of the team. It will be displayed in the selects and other places.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="kind"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Type</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select type of the team" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="team">Team</SelectItem>
                  <SelectItem value="group">Group</SelectItem>
                  <SelectItem value="department">Department</SelectItem>
                </SelectContent>
              </Select>
              <FormDescription>
                This is a type of the team. It can be a team, group or department. Use "Group" for custom groups of
                people
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="member_ids"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Members</FormLabel>
              <MultiSelector onValuesChange={field.onChange} values={field.value} data={memberOptions}>
                <MultiSelectorTrigger inputRef={inputRef}>
                  {/* <span className="mx-3 text-sm text-muted-foreground">Select people to invite</span> */}
                  <MultiSelectorInput ref={inputRef} placeholder="Select people to invite" />
                </MultiSelectorTrigger>
                <MultiSelectorContent>
                  <MultiSelectorList>
                    {memberOptions.map((option) => (
                      <MultiSelectorItem key={option.value} value={option.value.toString()}>
                        <div className="flex items-center space-x-2">
                          <span>{option.label}</span>
                        </div>
                      </MultiSelectorItem>
                    ))}
                  </MultiSelectorList>
                </MultiSelectorContent>
              </MultiSelector>
              <FormDescription>Select people to invite to this event</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="space-x-2">
          {ResetText && (
            <Button type="button" variant="secondary" onClick={() => form.reset(defaultValues)}>
              {ResetText}
            </Button>
          )}
          <Button type="submit">{SubmitText}</Button>
        </div>
      </form>
    </Form>
  );
};
export default FormTeam;
