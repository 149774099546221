import React from "react";
const SortIcon = ({ direction }) => {
  const askDirection = !direction || direction === "asc";
  const descDirection = !direction || direction === "desc";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
      {descDirection && <path d="M7 13L12 18L17 13H7Z" />}
      {askDirection && <path d="M17 11L12 6L7 11H17Z" />}
    </svg>
  );
};

export default SortIcon;
