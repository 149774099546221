import React from "react";
import { Outlet, createFileRoute, getRouteApi } from "@tanstack/react-router";
import Questions from "@/components/Metrics/Questions";
import { fetchQuestions } from "@/api/Metrics";
import { Skeleton } from "@/ui/skeleton";

export const Route = createFileRoute("/metrics")({
  component: Metrics,
  loader: () => fetchQuestions(),
  pendingComponent: MetricsLoading,
});

function Metrics() {
  const questions = Route.useLoaderData();
  return (
    <div className="h-screen w-full">
      <div className="grid h-full grid-cols-3">
        <div className="col-span-1 flex h-full flex-col gap-4 overflow-y-scroll border-r p-6">
          <Questions questions={questions} />
        </div>
        <div className="col-span-2 flex h-full flex-col gap-4 overflow-y-scroll p-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function MetricsLoading() {
  return (
    <div className="h-screen w-full">
      <div className="grid h-full grid-cols-3">
        <div className="col-span-1 flex h-full flex-col gap-4 overflow-y-scroll border-r p-6">
          <div className="space-y-2">
            <Skeleton className="h-8 w-20" />
            <div>
              <Skeleton className="h-6" />
              <Skeleton className="h-6 w-20" />
            </div>
          </div>
          <div>
            <Skeleton className="h-10" />
          </div>
          <div className="space-y-4">
            <div className="space-y-2">
              <Skeleton className="h-5 w-16" />
              <div className="flex flex-col gap-4">
                <Skeleton className="h-24" />
                <Skeleton className="h-24" />
                <Skeleton className="h-24" />
              </div>
            </div>
            <div className="space-y-2">
              <Skeleton className="h-5 w-16" />
              <div className="flex flex-col gap-4">
                <Skeleton className="h-24" />
                <Skeleton className="h-24" />
                <Skeleton className="h-24" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-2 flex h-full flex-col gap-4 overflow-y-scroll p-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
