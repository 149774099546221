import { Icons } from "@/components/Utils/Icons";

const KeyCell = ({ getValue }) => {
  return (
    <a href="#" target="_blank" rel="noopener noreferrer" className="link flex items-center gap-1">
      <span>{getValue()}</span>
      <Icons.Link size={12} />
    </a>
  );
};

export default KeyCell;
