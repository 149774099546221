import React from "react";

const statusColors = {
  "In Progress": "bg-primary-400 text-white",
  Idle: "bg-orange-500 text-white",
  Done: "bg-green-400 text-white",
  "To Do": "bg-gray-200 text-black",
};

const StatusCell = ({ getValue }) => {
  const status = getValue();
  const colorClasses = statusColors[status] || "bg-gray-500 text-white";
  return <div className={`w-[120px] rounded-xl text-center ${colorClasses}`}>{status}</div>;
};

export default StatusCell;
