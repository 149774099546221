import Title from "@/components/Title";
import Tabs from "@/components/Tabs";
import { Link, Outlet, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/deliverables/$id")({
  component: Deliverable,
});

function Deliverable() {
  return (
    <div className="flex flex-1 flex-col">
      <div className="flex flex-1 flex-col gap-6 bg-white p-6">
        {/* <%= render partial: 'shared/breadcrumbs', locals: {breadcrumbs: {'Deliverables': deliverables_path, "TEL-1235" => nil}} %> */}
        <Title issue_key="TEL-1235" title="Redirection to Customer Support" status="In Progress" />
      </div>
      <div className="flex flex-1 flex-col gap-6 bg-gray-100 p-6">
        <Tabs
          tabs={[
            { name: "Overview", to: "./", activeOptions: { exact: true } },
            { name: "Work items", to: "./work_items" },
            { name: "Metrics", to: "./metrics" },
            { name: "Activity", to: "./activity" },
          ]}
        />
        <Outlet />
      </div>
    </div>
  );
}
