import WorkItemsTable from "@/components/WorkItemsTable";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/deliverables/$id/work_items")({
  component: DeliverableWorkItems
});

function DeliverableWorkItems() {
  return <WorkItemsTable />;
}
