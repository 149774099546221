import React from "react";
import { createFileRoute } from '@tanstack/react-router'
import FormTeam from "./-FormTeam";
import { toast } from "@/ui/use-toast";
import Spinner from "@/ui/spinner";
import { useCreateTeam } from "@/api/Teams";
import { useMembersData } from "@/api/Members";

const NewTeam = () => {
  let memberOptions = [];

  const { data: members, isLoading: isMembersLoading } = useMembersData();
  const { mutate } = useCreateTeam();
  if (isMembersLoading) {
    return <Spinner />;
  } else {
    memberOptions = members.map((member) => {
      return {
        value: member.id.toString(),
        label: member.name,
      };
    });
  }

  const onSubmit = (data) => {
    console.log("data", data);
    mutate(data, {
      onSuccess: (json) => {
        toast({
          title: "You submitted the following values:",
          description: (
            <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
              <code className="text-white">{JSON.stringify(json, null, 2)}</code>
            </pre>
          ),
        });
      },
    });
  }
  return <FormTeam onSubmit={onSubmit} defaultValues={{}} memberOptions={memberOptions} SubmitText="Create Team" />;
};

export const Route = createFileRoute("/settings/teams/new")({
  component: NewTeam,
});