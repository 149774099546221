import { Link } from "@tanstack/react-router";

function AssigneeCell({ row, getValue }) {
  return (
    <Link to="/members/$id" params={{id: getValue().id}} className="link">
      <div className="h-full w-full">{getValue().name}</div>
    </Link>
  );
}

export default AssigneeCell;
