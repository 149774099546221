import React from "react";
import { Link } from "@tanstack/react-router";
import { Icons } from "@/components/Utils/Icons";

const Sidebar = () => {
  return (
    <>
      <button
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        className="ms-3 mt-2 inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 sm:hidden">
        <span className="sr-only">Open sidebar</span>
        <Icons.Menu size={24} />
      </button>
      <aside
        id="logo-sidebar"
        className="fixed left-0 top-0 z-0 h-screen w-20 -translate-x-full transition-transform sm:translate-x-0"
        aria-label="Sidebar">
        <div className="flex h-screen flex-col overflow-y-auto bg-primary-900 pb-4">
          <a href="https://flowbite.com/">
            <div className="bg-primary-800 text-white">
              <Icons.Logo size={64} />
            </div>
          </a>
          <ul className="justify-center font-semibold">
            <li>
              <Link
                to="/teams/1"
                className="team data-active:bg-blue-600 data-active:text-white flex flex-col items-center py-2 text-gray-400 hover:text-white">
                <Icons.Team size={24} />
                <span className="text-xs">Team</span>
              </Link>
            </li>
            <li>
              <Link
                to="/members/0"
                className="team data-active:bg-blue-600 data-active:text-white flex flex-col items-center py-2 text-gray-400 hover:text-white">
                <Icons.Member size={24} />
                <span className="text-xs">Member</span>
              </Link>
            </li>
            <li>
              <Link
                to="/metrics"
                className="team data-active:bg-blue-600 data-active:text-white flex flex-col items-center py-2 text-gray-400 hover:text-white">
                <Icons.BarChart size={24} />
                <span className="text-xs">Metrics</span>
              </Link>
            </li>
            <li>
              <Link
                to="/deliverables"
                className="team data-active:bg-blue-600 data-active:text-white flex flex-col items-center py-2 text-gray-400 hover:text-white">
                <Icons.Rocket size={24} />
                <span className="text-xs">Delivery</span>
              </Link>
            </li>
          </ul>
          <ul className="flex flex-grow justify-center">
            <div className="mt-auto flex-1">
              <li>
                <Link
                  to="/settings"
                  className="team data-active:bg-blue-600 data-active:text-white flex flex-col items-center py-2 text-gray-400 hover:text-white">
                  <Icons.Settings size={24} />
                  <span className="text-xs">Settings</span>
                </Link>
              </li>
              <li>
                <a href="#" className="flex flex-col items-center py-2">
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="size-10 rounded-full"
                    src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                    alt="user photo"></img>
                </a>
              </li>
            </div>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
