import React from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Toaster } from "@/ui/toaster";
import { RouterProvider, createRouteMask, createRouter } from "@tanstack/react-router";

import { routeTree } from "./routeTree.gen";

const membersZeroToMembersMask = createRouteMask({
  routeTree,
  from: '/members/0',
  to: '/members',
  unmaskOnReload: true,
})
const router = createRouter({
  routeTree,
  routeMasks: [membersZeroToMembersMask],
})

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const HOUR = 1000 * 60 * 60;

document.addEventListener("turbo:load", () => {
  const rootElement = document.getElementById("root");
  const root = createRoot(rootElement);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: HOUR,
        gcTime: HOUR * 24,
      },
    },
  });

  root.render(
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <Toaster />
    </QueryClientProvider>,
    // <React.StrictMode>
    // </React.StrictMode>
  );
});
