import React, { useContext } from "react";
import { TableContext } from "./TableProvider";
import { flexRender } from "@tanstack/react-table";
import SortIcon from "./icons/SortIcon";

const TableMain = () => {
  const table = useContext(TableContext).table;
  const columns = table.getAllColumns();

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm">
        <thead className="text-gray-500 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <td
                    key={header.id}
                    className="px-5 py-3"
                    colSpan={header.colSpan}
                    style={{ width: `${header.getSize()}px` }}>
                    <div
                      className="flex cursor-pointer items-center gap-1"
                      onClick={header.column.getToggleSortingHandler()}>
                      <span>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </span>
                      {header.column.getCanSort() && (
                        <div className="text-gray-300">
                          <SortIcon direction={header.column.getIsSorted()} />
                        </div>
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id} data-state={row.getIsSelected() && "selected"} className="border-t hover:bg-gray-50">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="px-5 py-3">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="h-24 text-center">
                No results.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableMain;
