import React from "react";
import { Icons } from "../Utils/Icons";

interface TitleProps {
  issue_key: string;
  title: string;
  status: string;
}
const Title = ({ issue_key, title, status }: TitleProps) => (
  <div className="flex gap-4">
    <div data-controller="progress" className="size-24">
      <div id="chart" className="chart h-full w-full"></div>
    </div>
    <div className="flex flex-col items-start gap-2">
      <a href="#" target="_blank" rel="noopener noreferrer" className="link flex items-center gap-1">
        <span>{issue_key}</span>
        <Icons.Link size={20} />
      </a>
      <span className="text-2xl font-bold">{title}</span>
      <div className="min-w-[120px] rounded-xl bg-primary-400 px-5 text-center text-sm text-white">{status}</div>
    </div>
  </div>
);

export default Title;