import React from "react";

const AngleDownIcon = ({ className }) => {
  return (
    <svg
      className={className}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24">
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.6" d="m19 9-7 7-7-7" />
    </svg>
  );
};

export default AngleDownIcon;
