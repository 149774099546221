import React from "react";

function ProgressCell({ getValue }) {
  const [done, inProgress, toDo] = getValue();

  return (
    <div className="flex h-5 w-full overflow-hidden rounded-full font-medium text-xs">
      <div style={{ width: `${done}%` }} className="h-full bg-green-400 text-center content-center text-white">
        {done}%
      </div>
      <div style={{ width: `${inProgress}%` }} className="h-full bg-primary-400 text-center content-center text-white">
        {inProgress}%
      </div>
      <div style={{ width: `${toDo}%` }} className="h-full bg-gray-200 text-center content-center text-black">
        {toDo}%
      </div>
    </div>
  );
}

export default ProgressCell;
