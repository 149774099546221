import React, { useContext } from "react";
import Pagination from "./Pagination";
import { TableContext } from "./TableProvider";

const TablePagination = () => {
  const table = useContext(TableContext).table;
  const pageSize = table.getState().pagination.pageSize;
  const currentPage = table.getState().pagination.pageIndex + 1;
  return (
    <div className="grid grid-cols-1 items-center border-t border-gray-200 bg-white px-4 py-2 md:grid-cols-3">
      <div className="text-sm text-gray-700">
        Showing <span className="font-medium">{pageSize * (currentPage - 1) + 1}</span> -{" "}
        <span className="font-medium">{Math.min(pageSize * currentPage, table.getRowCount())}</span> of{" "}
        <span className="font-medium">{table.getRowCount()}</span>
      </div>
      <div className="justify-self-center">
        <Pagination
          currentPage={currentPage}
          pageCount={table.getPageCount()}
          pageSize={pageSize}
          onPageChange={(page) => table.setPageIndex(page - 1)}
        />
      </div>
      <div className="justify-self-end text-sm text-gray-700">
        Rows per page:
        <span className="font-medium"> {pageSize}</span>
      </div>
    </div>
  );
};

export default TablePagination;
