import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useQuery } from "react-query";

dayjs.extend(utc)

export const fetchMetricTileData = async (id: number, teamId: number): Promise<any> => {
  const response = await fetch(`/metrics/${id}/tile_data`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      filters: {
        start_time: dayjs.utc("2020-01-01").format(),
        end_time: dayjs.utc("2020-07-01").format(),
        team_id: teamId,
      },
    }),
  });
  return response.json();
};

export const useMetricTileData = (id: number, teamId: number) => {
  return useQuery<any>({ queryKey: ["metric_data", id, teamId], queryFn: () => fetchMetricTileData(id, teamId)});
};