import React from "react";
import { useParams, createFileRoute } from "@tanstack/react-router";
import { toast } from "@/ui/use-toast";
import Spinner from "@/ui/spinner";
import FormTeam from "./-FormTeam";
import { useUpdateTeam, useTeamData } from "@/api/Teams";
import _ from "lodash";
import { useMembersData } from "@/api/Members";

const ShowTeam = () => {
  const { teamId } = useParams({ strict: false });
  const { data, isLoading } = useTeamData(teamId);
  let defaultValues = {};
  let memberOptions = [];

  const { data: members, isLoading: isMembersLoading } = useMembersData();
  const { mutate } = useUpdateTeam();

  if (isLoading || isMembersLoading) {
    return <Spinner />;
  } else {
    defaultValues.name = data.name;
    defaultValues.kind = data.kind;
    defaultValues.member_ids = data.member_ids.map((memberId) => memberId.toString());
    // defaultValues.member_ids = data.members.map((member) => member.id.toString());
    memberOptions = members.map((member) => {
      return {
        value: member.id.toString(),
        label: member.name,
      };
    });
  }
  const onSubmit = (data) => {
    mutate({ teamId, data }, {
      onSuccess: (json) => {
        toast({
          title: "You submitted the following values:",
          description: (
            <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
              <code className="text-white">{JSON.stringify(json, null, 2)}</code>
            </pre>
          ),
        });
      },
    });
  };

  return (
    <FormTeam
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      memberOptions={memberOptions}
      SubmitText="Update Team"
      ResetText="Cancel"
    />
  );
};

export const Route = createFileRoute("/settings/teams/$teamId")({
  component: ShowTeam,
});