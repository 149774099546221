import React from "react";
import AngleDownIcon from "../Icon/AngleDownIcon";
import AngleUpIcon from "../Icon/AngleUpIcon";

const AnswerDoc = () => {
  const data = `A Cumulative Flow Diagram (CFD) is a powerful tool that helps you visualize your team's workflow over time.
          It's like a traffic flow chart, but for your work! How it Works: Imagine a graph with time on the X-axis and
          the number of work items on the Y-axis. Different colored lines represent the various stages of your workflow
          (e.g., To Do, In Progress, Done).`;
  const [showMore, setShowMore] = React.useState(false);
  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  const ShowMore = () => {
    return (
      <>
        <div>{data.slice(0, 100)}</div>
        <div className="flex cursor-pointer gap-1 self-start text-sm text-primary-500" onClick={handleShowMore}>
          <div>Show more</div>
          <AngleDownIcon className="size-5" />
        </div>
      </>
    );
  };
  const ShowLess = () => {
    return (
      <>
        <div>{data}</div>
        <div className="flex cursor-pointer gap-1 self-start text-sm text-primary-500" onClick={handleShowMore}>
          <div>Show less</div>
          <AngleUpIcon className="size-5" />
        </div>
      </>
    );
  };

  return (
    <div className="rounded-lg border p-4">
      <div className="flex flex-col gap-2">{showMore ? <ShowLess /> : <ShowMore />}</div>
    </div>
  );
};

export default AnswerDoc;
