import { createFileRoute } from "@tanstack/react-router";
import classNames from "classnames";
import { Icons } from "@/components/Utils/Icons";
import WorkItemsTable from "@/components/WorkItemsTable";

export const Route = createFileRoute("/deliverables/$id/")({
  component: Deliverable,
});

function Deliverable() {
  return (
    <div className="grid grid-cols-3 gap-6">
      <div className="col-span-2 flex flex-col gap-6">
        <Timeline />
        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
          <Tile name="Lifetime cost" value="$ 34,700" difference="4%" movement="fall" color="green" />
          <Tile name="Lifetime effort" value="8 person-month" difference="4%" movement="fall" color="green" />
          <Tile name="% days of active work" value="67%" difference="4%" movement="fall" color="green" />
        </div>
        <WorkItemsTable />
      </div>
      <div className="flex flex-col gap-6">
        <RecentActivity />
      </div>
    </div>
  );
}

const Timeline = () => (
  <div className="flex flex-col rounded-xl bg-white px-4 py-2">
    <div className="grid grid-cols-3 text-sm">
      <div>Started</div>
      <div className="text-center">Traget date</div>
      <div className="text-end">Forecast</div>
    </div>
    <div className="flex h-6 items-center px-1">
      <div className="relative flex-1">
        <div className="absolute -top-1.5 z-10 flex w-full translate-y-[0.5px] justify-between">
          <div className="relative z-10 flex justify-end">
            <div className="size-3 rounded-full border-2 border-primary-500 bg-white dark:border-neutral-600 dark:bg-neutral-800"></div>
          </div>
          <div className="relative z-10 flex justify-end">
            <div className="size-3 rounded-full border-2 border-gray-200 bg-white dark:border-neutral-600 dark:bg-neutral-800"></div>
          </div>
          <div className="relative z-10 flex justify-end">
            <div className="size-3 rounded-full border-2 border-gray-200 bg-white dark:border-neutral-600 dark:bg-neutral-800"></div>
          </div>
        </div>
        <div className="relative flex flex-1">
          <div style={{ width: "44%" }} className="h-0.5 bg-primary-500 dark:bg-gray-700"></div>
          <div style={{ width: "56%" }} className="h-0.5 bg-gray-200 dark:bg-gray-700"></div>
        </div>
      </div>
    </div>
    <div className="grid grid-cols-3 text-sm">
      <div>Jan 9 2024</div>
      <div className="text-center">May 20 2024</div>
      <div className="text-end">Apr 2 2024</div>
    </div>
  </div>
);

interface TileProps {
  name: string;
  value: string;
  difference: string;
  movement: string;
  color: string;
}
const Tile = ({ name, value, difference, movement, color }: TileProps) => {
  let MovementIcon = () => <span>-</span>;
  if (movement === "raise") {
    MovementIcon = () => <Icons.TrendingUp size={16} />;
  } else if (movement === "fall") {
    MovementIcon = () => <Icons.TrendingDown size={16} />;
  }
  return (
    <div className="flex flex-col rounded-xl bg-white dark:border-neutral-700 dark:bg-neutral-800">
      <div className="px-4 py-8">
        <div className="flex items-center gap-x-4">
          <h3 className="text-xl font-semibold text-gray-800 dark:text-neutral-200 sm:text-2xl">{value}</h3>
          <span
            className={classNames("flex items-center gap-x-1", color === "green" ? "text-green-500" : "text-red-500")}>
            <MovementIcon />
            <span className="inline-block text-sm">{difference}</span>
          </span>
        </div>
        <div className="flex items-center gap-x-2 text-gray-400 dark:text-neutral-600">
          <p className="text-sm tracking-wide">{name}</p>
          <div className="hs-tooltip">
            <div className="hs-tooltip-toggle">
              <svg
                className="size-4 flex-shrink-0"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round">
                <circle cx="12" cy="12" r="10" />
                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                <path d="M12 17h.01" />
              </svg>
              <span
                className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible invisible absolute z-10 inline-block rounded bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity dark:bg-neutral-700"
                role="tooltip">
                The number of daily users
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RecentActivity = () => (
  <div className="overflow-hidden rounded-xl bg-white">
    <div className="flex flex-col self-stretch border-b px-4 py-2">
      <div className="text-base font-semibold">Recent activity</div>
    </div>
    <div className="px-4 pt-4">
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <div className="relative ml-5 flex gap-4">
          <div className="absolute h-full border-l"></div>
          <div className="mb-5 flex w-full items-center gap-3">
            <img
              className="z-10 -ml-5 size-10 translate-x-[0.5px] self-start rounded-full border"
              src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
              alt="user photo"
            />
            <div className="flex flex-1">
              <p className="flex-1 text-sm">Profile informations changed.</p>
              <p className="text-xs text-gray-500">3 min ago</p>
            </div>
          </div>
        </div>
      ))}
    </div>
    <div className="flex w-full justify-center border-t px-4 py-2">
      <a href="#" className="link">
        Show more
      </a>
    </div>
  </div>
);
