import React from "react";

const PageTitle = ({ title }: { title: String }) => {
  return (
    <div className="flex justify-between self-stretch">
      <b className="text-2xl">{title}</b>
    </div>
  );
};

export default PageTitle;
