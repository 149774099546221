import { useState } from "react";
import DATA from "../DeliverablesTable/data";
import Table from "../Table";
import KeyCell from "../Table/cells/KeyCell";
import StatusCell from "../Table/cells/StatusCell";
import AssigneeCell from "../Table/cells/AssigneeCell";

const columns = [
  {
    accessorKey: "key",
    header: "Key",
    maxSize: 120,
    cell: KeyCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "title",
    header: "Title",
    size: 400,
    cell: (props) => <p>{props.getValue()}</p>,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "assignee",
    header: "Assignee",
    size: 160,
    cell: AssigneeCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "status",
    header: "Status",
    size: 160,
    maxSize: 160,
    cell: StatusCell,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
];

const WorkItemsTable = () => {
  const [data, _setData] = useState(DATA);

  return (
    <Table data={data} columns={columns}>
      <Table.Header name="Work items">
        <Table.Search />
      </Table.Header>
      <Table.Main />
      <Table.Pagination />
    </Table>
  );
};
export default WorkItemsTable;
