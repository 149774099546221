import { Controller } from "@hotwired/stimulus";
import { bottom, left } from "@popperjs/core";
import * as echarts from "echarts";

// Connects to data-controller="team"
export default class extends Controller {
  static targets = ["storyPoints", "workItems"];
  static classes = ["active", "inactive", "default"];

  initialize() {
    var container = document.getElementById("chart");
    var myChart = echarts.init(container, null, { renderer: "svg" });
    window.addEventListener("resize", function () {
      myChart.resize();
    });
    this.container = container;
    this.myChart = myChart;
  }

  connect() {
    const customFormatter = (params) => {
      if (params.seriesIndex === 3) {
        return params.value.slice(1, 4).reduce((sum, value) => sum + value, 0);
      } else if (params.seriesIndex === 5) {
        return params.value.slice(5, 6).reduce((sum, value) => sum + value, 0);
      }
    };
    var defaultBarSeries = {
      type: "bar",
      barGap: "10%",
      barCategoryGap: "20%",
      label: {
        show: true,
        formatter: function (params) {
          const value = params.value[params.encode.y[0]];
          return value == 0 ? "" : value;
        },
      },
      // emphasis: {
      //   focus: "series",
      // },
    };

    var option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        width: "80%",
        containLabel: true,
        left: "16",
        right: "0",
        bottom: "0",
        top: "16",
        tooltip: {
          show: true,
          trigger: "axis",
        },
      },
      legend: {
        type: "scroll",
        orient: "vertical",
        right: 16,
        align: "left",
        itemWidth: 32,
        itemHeight: 12,
        itemGap: 16,
        top: "middle",
        icon: "path://M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6C32 9.31371 29.3137 12 26 12H6C2.68629 12 0 9.31371 0 6Z",
        textStyle: {
          width: 120,
          padding: [0, 0, 0, 3],
          overflow: "truncate",
        },
        data: ["Added", "Newly Comitted", "Carryover", "Completed"],
      },
      xAxis: {
        type: "category",
        splitLine: {
          show: false,
        },
        axisTick: {
          show: true,
          length: 8,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          margin: 12,
        },
        axisLine: {
          show: true,
        },
        axisTick: {
          show: true,
          length: 8,
        },
        splitLine: {
          show: true,
        },
        nameLocation: "middle",
        nameRotate: 90,
        nameGap: 32,
        maxInterval: 40,
      },
      series: [
        {
          ...defaultBarSeries,
          name: "Carryover",
          stack: "Planned",
          itemStyle: {
            color: "#FFA39E",
          },
        },
        {
          ...defaultBarSeries,
          name: "Newly Comitted",
          stack: "Planned",
          itemStyle: {
            color: "#DDDDEB",
          },
        },
        {
          ...defaultBarSeries,
          name: "Added",
          stack: "Planned",
          itemStyle: {
            color: "#A2A2FC",
          },
        },
        {
          ...defaultBarSeries,
          stack: "Planned",
          dimensions: null,
          label: {
            show: true,
            position: "top",
            formatter: (params) => customFormatter(params),
          },
        },
        {
          ...defaultBarSeries,
          name: "Completed",
          stack: "Completed",
          type: "bar",
        },
        {
          ...defaultBarSeries,
          stack: "Completed",
          type: "bar",
          label: {
            show: true,
            position: "top",
            formatter: (params) => customFormatter(params),
          },
        },
      ],
    };

    // Display the chart using the configuration items and data just specified.
    this.myChart.setOption(option);
    this.show_story_points();
  }

  show_story_points() {
    var option = {
      dataset: {
        source: [
          ["Sprint", "Carryover", "Newly Committed", "Added", "Total planned", "Completed", "Total Completed"],
          ["Sprint 23", 12, 37, 36, 0, 46, 0],
          ["Sprint 24", 0, 37, 39, 0, 48, 0],
          ["Sprint 25", 9, 35, 28, 0, 49, 0],
          ["Sprint 26", 12, 29, 23, 0, 64, 0],
          ["Sprint 27", 0, 47, 37, 0, 64, 0],
          ["Sprint 28", 8, 63, 20, 0, 60, 0],
        ],
      },
      yAxis: {
        name: "Story Points",
      },
    };
    this.activateClasses(this.storyPointsTarget, this.workItemsTarget, this.activeClasses, this.inactiveClasses)
    this.myChart.setOption(option);
  }

  show_work_items() {
    var option = {
      dataset: {
        source: [
          ["Sprint", "Carryover", "Newly Committed", "Added", "Total planned", "Completed", "Total Completed"],
          ["Sprint 23", 2, 13, 11, 0, 16, 0],
          ["Sprint 24", 0, 15, 11, 0, 17, 0],
          ["Sprint 25", 3, 15, 12, 0, 21, 0],
          ["Sprint 26", 3, 11, 9, 0, 34, 0],
          ["Sprint 27", 0, 17, 18, 0, 30, 0],
          ["Sprint 28", 3, 24, 10, 0, 27, 0],
        ],
      },
      yAxis: {
        name: "Work items",
      },
    };
    this.activateClasses(this.workItemsTarget, this.storyPointsTarget, this.activeClasses, this.inactiveClasses)
    this.myChart.setOption(option);
  }

  activateClasses(activeTarget, inactiveTarget, activeClasses, inactiveClasses) {
    activeTarget.classList.add(...activeClasses)
    activeTarget.classList.remove(...inactiveClasses)
    inactiveTarget.classList.remove(...activeClasses)
    inactiveTarget.classList.add(...inactiveClasses)
  }
}