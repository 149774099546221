import { useQueryClient, useQuery, useMutation, UseQueryResult, UseMutationResult } from "react-query";

export interface Team {
  id: number;
  name: string;
  kind: string;
  members: string[];
}

export const fetchTeams = async (): Promise<Team[]> => {
  const response = await fetch(`/teams.json`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const createTeam = async (data: Team): Promise<Team> => {
  const response = await fetch(`/teams.json`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const updateTeam = async (teamId: number, data: Partial<Team>): Promise<Team> => {
  const response = await fetch(`/teams/${teamId}.json`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const fetchTeam = async (teamId: number): Promise<Team> => {
  const response = await fetch(`/teams/${teamId}.json`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const useTeamsData = (): UseQueryResult<Team[]> => {
  return useQuery<Team[]>({ queryKey: ["teams"], queryFn: fetchTeams });
};

export const useTeamData = (teamId: number): UseQueryResult<Team> => {
  return useQuery<Team>({ queryKey: ["teams", teamId], queryFn: () => fetchTeam(teamId) });
};

export const useUpdateTeam = (): UseMutationResult<Team, unknown, { teamId: number, data: Partial<Team> }> => {
  const queryClient = useQueryClient();
  return useMutation<Team, unknown, { teamId: number, data: Partial<Team> }>({
    mutationFn: (variables) => updateTeam(variables.teamId, variables.data),
    onSuccess: (data) => {
      queryClient.setQueryData(["teams", data.id.toString()], data)
    },
  });
};
export const useCreateTeam = (): UseMutationResult<Team, unknown, Team> => {
  const queryClient = useQueryClient();
  return useMutation<Team, unknown, Team>({
    mutationFn: createTeam,
    onSuccess: (data) => {
      queryClient.setQueryData<Team[]>(["teams"], (old_data) => [...old_data, data])
    },
  });
}
