import React from "react";
import Search from "@/ui/search";
import { MetricType, QuestionType } from "./types";
import { Link } from "@tanstack/react-router";
import { Icons } from "../Utils/Icons";

const Questions = ({ questions }: { questions: QuestionType[] }) => {
  const [filter, setFilter] = React.useState("");
  const filteredData = questions?.filter(
    (question) =>
      question.title.toLowerCase().includes(filter.toLowerCase()) ||
      question.metrics.some((metric) => metric.name.toLowerCase().includes(filter.toLowerCase())),
  );
  const groupedData: { [key: string]: QuestionType[] } = filteredData?.reduce((groups, question) => {
    const group = question.group;
    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(question);
    return groups;
  }, {});

  return (
    <>
      <div>
        <div className="text-2xl font-bold">Metrics</div>
        <div className="text-gray-500">Ask any questions about your team and process performance</div>
      </div>
      <Search value={filter} onChange={setFilter} />
      <div className="flex h-full flex-col gap-4">
        {Object.entries(groupedData).map(([title, questions], index) => (
          <QuestionGroup key={index} title={title} questions={questions} />
        ))}
      </div>
    </>
  );
};

const QuestionGroup = ({ title, questions }: { title: string; questions: QuestionType[] }) => {
  return (
    <div className="flex flex-1 flex-col gap-1">
      <span className="text-sm text-primary-500">{title}</span>
      <div className="flex flex-col gap-4">
        {questions.map((question) => (
          <Question key={question.id} question={question} />
        ))}
      </div>
    </div>
  );
};

const Question = ({ question }: { question: QuestionType }) => {
  const { id, title, metrics, favorite } = question;

  return (
    <Link
      to={"/metrics/$id"}
      params={{ id: id.toString() }}
      search={(prev) => ({ teamId: 1, from: '2020-01-01', to: '2020-06-30', ...prev })}
      className="group flex cursor-pointer gap-4 rounded-lg border p-4 hover:bg-gray-100 focus:bg-gray-200 data-active:bg-blue-600 data-active:text-white">
      <div className="flex flex-1 flex-col gap-4">
        <div className="text-sm">{title}</div>
        <QuestionMetrics metrics={metrics} />
      </div>
      <div className="self-center">
        <Icons.Star
          fill={favorite ? "currentColor" : "none"}
          className="size-6 text-primary-500 group-data-active:text-white"
        />
      </div>
    </Link>
  );
};

const QuestionMetrics = ({ metrics }: { metrics: MetricType[] }) => {
  return (
    <div className="flex flex-wrap gap-2">
      {metrics.map((metric) => (
        <div
          key={metric.id}
          className="rounded-full border bg-white px-1.5 py-0.5 text-xs leading-none text-primary-700">
          {metric.name}
        </div>
      ))}
    </div>
  );
};

export default Questions;
