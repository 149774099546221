import React from "react";
import { useState } from "react";
import DATA from "./data";
import StatusCell from "../Table/cells/StatusCell";
import ProgressCell from "./ProgressCell";
import dayjs from "dayjs";
import KeyCell from "../Table/cells/KeyCell";
import TitleCell from "../Table/cells/TitleCell";
import Table from "../Table";

const columns = [
  {
    accessorKey: "key",
    header: "Key",
    maxSize: 120,
    cell: KeyCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "title",
    header: "Title",
    size: 400,
    cell: TitleCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "status",
    header: "Status",
    size: 160,
    maxSize: 160,
    cell: StatusCell,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "progress",
    header: "Progress",
    enableSorting: false,
    size: 200,
    cell: ProgressCell,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "target_date",
    header: "Target",
    size: 140,
    cell: (props) => <p>{dayjs(props.getValue()).format("MMM D YYYY")}</p>,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "forecast_date",
    header: "Forecast",
    size: 140,
    cell: (props) => <p>{dayjs(props.getValue()).format("MMM D YYYY")}</p>,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
];

const DeliverablesTable = () => {
  const [data, setData] = useState(DATA);

  return (
    <Table data={data} columns={columns}>
      <Table.Header name="Deliverables" description="asdf">
        <Table.Search />
      </Table.Header>
      <Table.Main />
      <Table.Pagination />
    </Table>
  );
};
export default DeliverablesTable;
