import Tabs from "@/components/Tabs";
import TeamSelect from "@/components/TeamSelect";
import { AnyRoute, Outlet, createFileRoute, useNavigate, useParams } from "@tanstack/react-router";
import { useEffect, useState } from "react";

export const Route = createFileRoute("/teams/$id")({
  component: Team,
});

interface Params {
  id: string;
}

function Team() {
  const { id } = Route.useParams();
  const navigate = useNavigate();
  const [teamId, setTeamId] = useState(id);
  useEffect(() => {
    if (teamId) {
      navigate({ to: "/teams/$id", params: { id: teamId }, replace: true });
    }
  }, [teamId]);
  return (
    <div className="flex flex-1 flex-col gap-6 bg-gray-100 p-6">
      {/* <%= render partial: 'shared/breadcrumbs', locals: {breadcrumbs: {'Members': members_path, 'Alice Brown' => nil}} %> */}
      {/* <%= render partial: 'shared/page_title', locals: {title: 'Alice Brown'} %> */}
      <TeamSelect teamId={teamId} setTeamId={setTeamId} />
      <Tabs
        tabs={[
          { name: "Overview", to: "./", activeOptions: { exact: true } },
          { name: "Metrics", to: "./metrics" },
          { name: "Activity", to: "./activity" },
        ]}
      />
      <Outlet />
    </div>
  );
}
