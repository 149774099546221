import { QueryFunctionContext, useQuery } from "react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface MetricDataFilters {
  start_time: string;
  end_time: string;
  team_id: string;
}

interface MetricDataResponse {
  // Define the structure of the response data here
}

export const fetchMetricData = async (
  id: string | number,
  viewIndex: number,
  from: string,
  to: string,
  teamId: string | number
): Promise<MetricDataResponse> => {
  const start_time = dayjs.utc(from).startOf("day").format("YYYY-MM-DDTHH:mm:ssZ");
  const end_time = dayjs.utc(to).endOf("day").format("YYYY-MM-DDTHH:mm:ssZ");
  const response = await fetch(`/metrics/${id}/data`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      view_index: viewIndex,
      filters: {
        start_time,
        end_time,
        team_id: teamId,
      },
    }),
  });
  return response.json();
};

export const useMetricData = (
  id: string | number,
  viewIndex: number,
  from: string,
  to: string,
  teamId: string | number
) => {
  return useQuery<MetricDataResponse, Error>({
    queryKey: ["metric_data", id, viewIndex, from, to, teamId],
    queryFn: (context: QueryFunctionContext) =>
      fetchMetricData(id, viewIndex, from, to, teamId),
  });
};