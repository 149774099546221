import React, { useContext } from "react";
import { TableContext } from "./TableProvider";
import Search from "../ui/search";

const TableSearch = () => {
  const filter = useContext(TableContext).filter;
  const setFilter = useContext(TableContext).setFilter;

  return (
    <div className="w-96 min-w-32">
      <Search value={filter} onChange={setFilter} />
    </div>
  );
};

export default TableSearch;
