import DeliverablesTable from "@/components/DeliverablesTable";
import PageTitle from "@/components/PageTitle";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/deliverables/")({
  component: Deliverables,
});

function Deliverables() {
  return (
    <div className="flex h-screen flex-col gap-6 bg-gray-100 p-6">
      <PageTitle title="Deliverables" />
      <DeliverablesTable />
    </div>
  );
}
