type TableHeaderProps = {
  name: string;
  description?: string;
  children: React.ReactNode;
};

const TableHeader: React.FC<TableHeaderProps> = ({ name, description, children }) => {
  return (
    <div className="flex border-b px-4 py-2">
      <div className="flex-1 flex-col content-center">
        <div className="text-base font-semibold">{name}</div>
        {description && <div className="text-xs text-gray-600">{description}</div>}
      </div>
      {children}
    </div>
  );
};

export default TableHeader;
