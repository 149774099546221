import { QuestionType } from "@/components/Metrics/types";

export const fetchQuestions = async (): Promise<QuestionType[]> => {
  const response = await fetch("/metrics/questions", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response.json();
};
