import React from "react";
import * as echarts from "echarts";
import { useECharts } from "@kbox-labs/react-echarts";
import classNames from "classnames";

const getValues = (index) => {
  var values = {
    dataset: {
      source: [
        ["Sprint", "Carryover", "Newly Committed", "Added", "Total planned", "Completed", "Total Completed"],
        ["Sprint 23", 12, 37, 36, 0, 46, 0],
        ["Sprint 24", 0, 37, 39, 0, 48, 0],
        ["Sprint 25", 9, 35, 28, 0, 49, 0],
        ["Sprint 26", 12, 29, 23, 0, 64, 0],
        ["Sprint 27", 0, 47, 37, 0, 64, 0],
        ["Sprint 28", 8, 63, 20, 0, 60, 0],
      ],
    },
    yAxis: {
      name: "Story Points",
    },
  };
  var values2 = {
    dataset: {
      source: [
        ["Sprint", "Carryover", "Newly Committed", "Added", "Total planned", "Completed", "Total Completed"],
        ["Sprint 23", 2, 13, 11, 0, 16, 0],
        ["Sprint 24", 0, 15, 11, 0, 17, 0],
        ["Sprint 25", 3, 15, 12, 0, 21, 0],
        ["Sprint 26", 3, 11, 9, 0, 34, 0],
        ["Sprint 27", 0, 17, 18, 0, 30, 0],
        ["Sprint 28", 3, 24, 10, 0, 27, 0],
      ],
    },
    yAxis: {
      name: "Work items",
    },
  };
  if (index === 0) {
    return values;
  } else {
    return values2;
  }
}

const SprintsChart = () => {
  const customFormatter = (params) => {
    if (params.seriesIndex === 3) {
      return params.value.slice(1, 4).reduce((sum, value) => sum + value, 0);
    } else if (params.seriesIndex === 5) {
      return params.value.slice(5, 6).reduce((sum, value) => sum + value, 0);
    }
  };
  const defaultBarSeries = {
    type: "bar",
    barGap: "10%",
    barCategoryGap: "20%",
    label: {
      show: true,
      formatter: function (params) {
        const value = params.value[params.encode.y[0]];
        return value == 0 ? "" : value;
      },
    },
    // emphasis: {
    //   focus: "series",
    // },
  };

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      width: "80%",
      containLabel: true,
      left: "16",
      right: "0",
      bottom: "0",
      top: "16",
      tooltip: {
        show: true,
        trigger: "axis",
      },
    },
    legend: {
      type: "scroll",
      orient: "vertical",
      right: 16,
      align: "left",
      itemWidth: 32,
      itemHeight: 12,
      itemGap: 16,
      top: "middle",
      icon: "path://M0 6C0 2.68629 2.68629 0 6 0H26C29.3137 0 32 2.68629 32 6C32 9.31371 29.3137 12 26 12H6C2.68629 12 0 9.31371 0 6Z",
      textStyle: {
        width: 120,
        padding: [0, 0, 0, 3],
        overflow: "truncate",
      },
      data: ["Added", "Newly Comitted", "Carryover", "Completed"],
    },
    xAxis: {
      type: "category",
      splitLine: {
        show: false,
      },
      axisTick: {
        show: true,
        length: 8,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        margin: 12,
      },
      axisLine: {
        show: true,
      },
      axisTick: {
        show: true,
        length: 8,
      },
      splitLine: {
        show: true,
      },
      nameLocation: "middle",
      nameRotate: 90,
      nameGap: 32,
      maxInterval: 40,
    },
    series: [
      {
        ...defaultBarSeries,
        name: "Carryover",
        stack: "Planned",
        itemStyle: {
          color: "#FFA39E",
        },
      },
      {
        ...defaultBarSeries,
        name: "Newly Comitted",
        stack: "Planned",
        itemStyle: {
          color: "#DDDDEB",
        },
      },
      {
        ...defaultBarSeries,
        name: "Added",
        stack: "Planned",
        itemStyle: {
          color: "#A2A2FC",
        },
      },
      {
        ...defaultBarSeries,
        stack: "Planned",
        dimensions: null,
        label: {
          show: true,
          position: "top",
          formatter: (params) => customFormatter(params),
        },
      },
      {
        ...defaultBarSeries,
        name: "Completed",
        stack: "Completed",
        type: "bar",
      },
      {
        ...defaultBarSeries,
        stack: "Completed",
        type: "bar",
        label: {
          show: true,
          position: "top",
          formatter: (params) => customFormatter(params),
        },
      },
    ],
  };
  const [index, setIndex] = React.useState(0);
  const [ref, _echartsInstance] = useECharts({ ...option, ...getValues(index) });
  return (
    <div className="rounded-xl border bg-white">
      <div className="flex justify-between border-b px-4 py-2">
        <span className="self-center font-semibold">Sprints</span>
          <div className="flex divide-x divide-primary-200 overflow-hidden rounded-2xl border border-primary-200 bg-white">
              <button
                onClick={() => setIndex(0)}
                type="button"
                className={classNames("px-3 py-1", { "bg-primary-500 text-white": index === 0 })}>
                Story Points
              </button>
              <button
                onClick={() => setIndex(1)}
                type="button"
                className={classNames("px-3 py-1", { "bg-primary-500 text-white": index === 1 })}>
                Work items
              </button>
          </div>
      </div>
      <div className="h-[440px] w-full p-6">
        <div className="h-full w-full" ref={ref} />
      </div>
    </div>
  );
};

export default SprintsChart;
