import React from "react";
import { useState } from "react";
import DATA from "../DeliverablesTable/data";
import Table from "../Table";
import KeyCell from "../Table/cells/KeyCell";
import TitleCell from "../Table/cells/TitleCell";
import StatusCell from "../Table/cells/StatusCell";
import ActivitiesCell from "../Table/cells/ActivitiesCell";
import dayjs from "dayjs";

const columns = [
  {
    accessorKey: "key",
    header: "Key",
    maxSize: 120,
    cell: KeyCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "title",
    header: "Title",
    size: 400,
    cell: TitleCell,
    enableColumnFilter: true,
    enableGlobalFilter: true,
    filterFn: "includesString",
  },
  {
    accessorKey: "activities",
    header: "Activity",
    size: 300,
    cell: ActivitiesCell,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "first_activity_date",
    header: "First activity",
    size: 140,
    cell: (props) => <p>{dayjs(props.getValue()).format("MMM D YYYY")}</p>,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "last_activity_date",
    header: "Last activity",
    size: 140,
    cell: (props) => <p>{dayjs(props.getValue()).format("MMM D YYYY")}</p>,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  }
];

const WorkItemsWithActivityTable = () => {
  const [data, _setData] = useState(DATA);

  return (
    <Table data={data} columns={columns}>
      <Table.Header name="Work items">
        <Table.Search />
      </Table.Header>
      <Table.Main />
      <Table.Pagination />
    </Table>
  );
};
export default WorkItemsWithActivityTable;
