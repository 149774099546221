import { Controller } from "@hotwired/stimulus";
import * as echarts from "echarts";

// Connects to data-controller="group"
export default class extends Controller {
  initialize() {
    var container = document.getElementById("chart");
    var myChart = echarts.init(container, null, { renderer: "svg" });
    window.addEventListener("resize", function () {
      myChart.resize();
    });
    this.container = container;
    this.myChart = myChart;
  }

  connect() {
    const defaultSeries = {
      type: "gauge",
      startAngle: 90,
      endAngle: -270,
      pointer: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          width: 7,
        },
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: false,
        distance: 50,
      },
      detail: {
        fontSize: 24,
        color: "inherit",
        formatter: "{value}%",
      },
      radius: "100%",
    };
    const option = {
      series: [
        {
          ...defaultSeries,
          progress: {
            show: true,
            overlap: true,
            roundCap: true,
            clip: false,
            width: 7,
            itemStyle: {
              color: "#7171F0",
            },
          },
          data: [
            {
              value: 90,
              title: {
                show: false,
              },
              detail: {
                show: false,
              },
            },
          ],
        },
        {
          ...defaultSeries,
          progress: {
            show: true,
            overlap: true,
            roundCap: true,
            clip: false,
            width: 7,
            itemStyle: {
              color: "#2DBD5D",
            },
          },
          data: [
            {
              value: 70,
              title: {
                show: false,
              },
              detail: {
                show: true,
                offsetCenter: ["0%", "0%"],
                color: "black",
              },
            },
          ],
        },
      ],
    };

    this.myChart.setOption(option);
  }
}
