import React from "react";
import _ from "lodash";
import classNames from "classnames";

import Spinner from "../ui/spinner";
import ChartBody from "./ChartBody";
import { useMetricData } from "../../api/metricData";
import { getRouteApi } from "@tanstack/react-router";
import { MetricType, ViewType } from "../Metrics/types";

const routeApi = getRouteApi("/metrics/$id");

const Chart = ({ metric }: { metric: MetricType }) => {
  const [viewIndex, setViewIndex] = React.useState(0);
  const { id } = metric;
  const views: ViewType[] = _.reject(metric.views, { tile_only: true });
  const { teamId, from, to } = routeApi.useSearch();
  const { isLoading, data, isError } = useMetricData(id, viewIndex, from, to, teamId);

  const body = () => {
    if (isLoading) {
      return <Spinner className="flex h-full items-center justify-center" />;
    } else if (isError) {
      return <div className="flex h-full items-center justify-center">Failed to load data</div>;
    } else {
      return <ChartBody rawData={data} />;
    }
  };
  return (
    <div className="rounded-xl border bg-white">
      <div className="flex justify-between border-b px-4 py-2">
        <span className="self-center font-semibold">{metric.name}</span>
        {views.length >= 2 && (
          <div className="flex divide-x divide-primary-200 overflow-hidden rounded-2xl border border-primary-200 bg-white">
            {views.map((view, index) => (
              <button
                key={index}
                onClick={() => setViewIndex(index)}
                type="button"
                className={classNames("px-3 py-1", { "bg-primary-500 text-white": viewIndex === index })}>
                {view.name}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="h-[440px] w-full p-6">{body()}</div>
    </div>
  );
};

export default Chart;
