import React from "react";
import { Link } from "@tanstack/react-router";

interface TabsProps {
  name: string;
  to: string;
  activeOptions?: { exact: boolean };
}
const Tabs = ({ tabs }: { tabs: TabsProps[] }) => {
  return (
    <div className="border-b border-gray-200 text-sm text-gray-500 dark:border-gray-700 dark:text-gray-400">
      <ul className="-mb-px flex gap-4">
        {tabs.map(({ name, ...props }) => (
          <li key={props.to} className="me-2">
            <Link
              {...props}
              className="inline-block rounded-t-lg border-b-2 border-transparent pb-3 hover:border-gray-300 hover:text-gray-600 data-active:border-blue-600 data-active:text-blue-600 dark:hover:text-gray-300">
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;