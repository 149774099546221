import React from "react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import _ from "lodash";
import { useTeamsData } from "@/api/Teams";
import { Skeleton } from "../ui/skeleton";
import { SelectLabel } from "@radix-ui/react-select";

const TeamSelect = ({teamId, setTeamId}: {teamId: string | undefined, setTeamId: (value: string | undefined) => void }) => {
  const { data, isLoading } = useTeamsData();

  if (isLoading) {
    return <Skeleton className="h-8 w-64 rounded-full" />;
  }

  const kinds = _.uniq(_.map(data, (team) => _.capitalize(team.kind)));
  const groupedOptions = kinds.map((kind) => {
    const teams = data.filter((team) => _.capitalize(team.kind) === kind);
    return {
      label: kind,
      options: teams.map((team) => ({
        value: team.id,
        label: team.name,
      })),
    };
  });

  return (
    <>
      <Select onValueChange={setTeamId} value={teamId}>
        <SelectTrigger className="h-8 w-64 rounded-full">
          <SelectValue placeholder="Select the team" />
        </SelectTrigger>
        <SelectContent className="p-2 shadow-select">
          {groupedOptions.map((group) => (
            <SelectGroup key={group.label}>
              <SelectLabel className="text-sm text-blue-800">{group.label}</SelectLabel>
              {group.options.map((option) => (
                <SelectItem className="rounded-md" key={option.value} value={option.value.toString()}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectGroup>
          ))}
        </SelectContent>
      </Select>
    </>
  );
};

export default TeamSelect;
