import React from "react";
import { Outlet, RouterProvider, createRouter, createRoute, createRootRoute } from "@tanstack/react-router";

import SidebarNav from "@/components/SidebarNav";
import { fetchTeams } from "@/api/Teams";
import _ from "lodash";
import { Skeleton } from "@/components/ui/skeleton";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/settings")({
  component: Settings,
  loader: () => fetchTeams(),
  pendingComponent: SettingsLoading,
});

function Settings() {
  const data = Route.useLoaderData();

  const teamsItems = data.map((team) => {
    return {
      title: `${_.upperFirst(team.kind)}: ${team.name}`,
      href: `/settings/teams/${team.id}`,
    };
  });
  const sidebarNavItems = [
    ...teamsItems,
    {
      title: "New Team",
      href: "/settings/teams/new",
    },
  ];
  return (
    <div className="h-screen w-full">
      <div className="grid h-full grid-cols-5">
        <div className="col-span-1 flex h-full flex-col gap-4 overflow-y-scroll bg-gray-100 p-6">
          <SidebarNav items={sidebarNavItems} />
        </div>
        <div className="col-span-4 flex h-full flex-col gap-4 overflow-y-scroll bg-white p-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function SettingsLoading() {
  return (
    <div className="h-screen w-full">
      <div className="grid h-full grid-cols-5">
        <div className="col-span-1 flex h-full flex-col gap-4 overflow-y-scroll bg-gray-100 p-6">
          <div className="space-y-6">
            <Skeleton className="h-6 bg-gray-200" />
            <Skeleton className="h-6 bg-gray-200" />
            <Skeleton className="h-6 bg-gray-200" />
            <Skeleton className="h-6 bg-gray-200" />
            <Skeleton className="h-6 bg-gray-200" />
          </div>
        </div>
        <div className="col-span-4 flex h-full flex-col gap-4 overflow-y-scroll bg-white p-6">
        </div>
      </div>
    </div>
  );
}
