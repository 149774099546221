import { useQuery, UseQueryResult } from "react-query";

export interface Member {
  id: number;
  name: string;
  email: string;
}

export const fetchMembers = async (): Promise<Member> => {
  const response = await fetch(`/members.json`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const createMember = async (data: Partial<Member>): Promise<Member> => {
  const response = await fetch(`/members.json`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const updateMember = async (memberId: number, data: Partial<Member>): Promise<Member> => {
  const response = await fetch(`/members/${memberId}.json`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const fetchMember = async (memberId: number): Promise<Member> => {
  const response = await fetch(`/members/${memberId}.json`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const useMembersData = (): UseQueryResult<Member[]> => {
  return useQuery<Member[]>({ queryKey: ["members"], queryFn: fetchMembers });
};

export const useMemberData = (memberId: number): UseQueryResult<Member> => {
  return useQuery<Member>({ queryKey: ["members", memberId], queryFn: () => fetchMember(memberId) });
};
