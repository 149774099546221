import _ from "lodash";

const STATUS_IN_PROGRESS = "In Progress";
const STATUS_IDLE = "Idle";
const STATUS_DONE = "Done";
const STATUS_TO_DO = "To Do";
const STATUSES = [STATUS_IN_PROGRESS, STATUS_IDLE, STATUS_DONE, STATUS_TO_DO];
const ACTIVITIES = ["Working", "Code Review", "Comment"];
const REASONS = [
  "Overdue the target date for 1 day",
  "Has low chance to be delivered on time",
  "Stuck in review for 28 hours",
  "No progress detected in the last 2 days",
  'Stalled for 3 days in "In development" status',
];

const DATA = [];

for (let i = 0; i < 70; i++) {
  const rand1 = Math.floor(Math.random() * 100);
  const rand2 = Math.floor(Math.random() * 100);
  const sorted = [rand1, rand2].sort((a, b) => a - b);

  const progress1 = sorted[0];
  const progress2 = sorted[1] - sorted[0];
  const progress3 = 100 - sorted[1];

  const randomStatus = STATUSES[Math.floor(Math.random() * STATUSES.length)];
  const activities = _.sampleSize(ACTIVITIES, Math.floor(Math.random() * ACTIVITIES.length) + 1);
  const reason = _.sample(REASONS);

  DATA.push({
    id: i + 1,
    key: `TEL-${1745 + i}`,
    title: `Feature long title name ${i + 1}`,
    status: randomStatus,
    progress: [progress1, progress2, progress3],
    target_date: new Date(2023, 10, 15 + i),
    forecast_date: new Date(2023, 10, 20 + i),
    assignee: {
      id: rand1,
      name: `User ${rand1}`,
    },
    activities: activities,
    first_activity_date: new Date(2023, 10, 15 + i),
    last_activity_date: new Date(2023, 10, 15 + i),
    reason: reason,
  });
}

export default DATA;
