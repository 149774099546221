const ActivitiesCell = ({ getValue }) => {
  const activities = getValue();
  return (
    <div className="flex gap-2">
      {activities.map((activity, index) => (
        <div key={index} className="w-[120px] rounded-xl bg-gray-200 text-center">
          <span>{activity}</span>
        </div>
      ))}
    </div>
  );
};

export default ActivitiesCell;
