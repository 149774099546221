import Tabs from "@/components/Tabs";
import { Outlet, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/members/$id")({
  component: Member,
});

function Member() {
  return (
    <div className="flex flex-1 flex-col gap-6 bg-gray-100 p-6">
      {/* <%= render partial: 'shared/breadcrumbs', locals: {breadcrumbs: {'Members': members_path, 'Alice Brown' => nil}} %> */}
      {/* <%= render partial: 'shared/page_title', locals: {title: 'Alice Brown'} %> */}
      <Tabs
        tabs={[
          { name: "Overview", to: "./", activeOptions: { exact: true } },
          { name: "Metrics", to: "./metrics" },
          { name: "Activity", to: "./activity" },
        ]}
      />
      <Outlet />
    </div>
  );
}
