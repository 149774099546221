import React from "react";
import { createFileRoute, useLoaderData, useParams, useParentMatches } from "@tanstack/react-router";
import TeamSelect from "@/components/TeamSelect";
import DateRangePicker from "@/components/DateRangePicker";
import AnswerDoc from "@/components/Metrics/AnswerDoc";
import Chart from "@/components/Chart";
import { Skeleton } from "@/ui/skeleton";
import { QuestionType } from "@/components/Metrics/types";

const DEFAULT_TEAM_ID = 1;

type MetricSearch = {
  teamId: number;
  from: string;
  to: string;
};
export const Route = createFileRoute("/metrics/$id")({
  component: Metric,
  validateSearch: (search: Record<string, unknown>): MetricSearch => {
    return {
      // teamId: search.teamId as number,
      // from: search.from as string,
      // to: search.to as string,
      teamId: (search.teamId as number) || DEFAULT_TEAM_ID,
      from: (search.from as string) || "2020-01-01",
      to: (search.to as string) || "2020-06-30",
    };
  },
});

function Metric() {
  const parentMatch = useParentMatches({ select: (matches) => matches.find((match) => match.routeId === "/metrics") });
  if (parentMatch.status === "pending") return MetricLoading();

  const { id } = useParams({ strict: false });
  const { teamId, from, to } = Route.useSearch();
  const questions: QuestionType[] = useLoaderData({ from: "/metrics" });

  const selectedQuestion: QuestionType | undefined = questions.find((question) => question.id.toString() === id);

  const navigate = Route.useNavigate();
  const onTeamChange = (value: string | undefined) => {
    navigate({ search: { teamId: Number(value), from: from, to: to } });
  };
  const onDateRangeChange = (value: string[]) => {
    navigate({ search: { teamId: teamId, from: value[0], to: value[1] } });
  };
  return (
    <div className="flex flex-col gap-2">
      <div className="relative mb-2 flex justify-between">
        <TeamSelect teamId={teamId.toString()} setTeamId={onTeamChange} />
        <DateRangePicker dateRange={[from, to]} setDateRange={onDateRangeChange} />
      </div>
      <div className="font-semibold">{selectedQuestion.title}</div>
      <AnswerDoc />
      {selectedQuestion.metrics.map((metric) => (
        <Chart key={metric.id} metric={metric} />
      ))}
    </div>
  );
}

function MetricLoading() {
  return (
    <div className="flex flex-col gap-2">
      <div className="relative mb-2 flex justify-between">
        <Skeleton className="h-8 w-64" />
        <Skeleton className="h-8 w-64" />
      </div>
      <Skeleton className="h-6 w-80" />
      <div className="flex flex-col gap-4">
        <Skeleton className="h-20" />
        <Skeleton className="h-[500px]" />
        <Skeleton className="h-20" />
        <Skeleton className="h-[500px]" />
      </div>
    </div>
  );
}
